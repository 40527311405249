import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const NotifyError = (text:string) => {
        toast.error(text, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
return toast
};


export default NotifyError;