import {useLocation} from "react-router-dom";
import React from "react";

export function useQueryParams() {
    const { search } = useLocation();

    return React.useMemo(() => {
        const entries = new URLSearchParams(search);

        if(!entries) return {};
        const result: any = {}

        // @ts-ignore
        for(const [key, value] of entries) {
            result[key] = value;
        }

        return result;
    }, [search]);
}
