import useDefaults from "./useDefaults";
import {useCallback, useContext} from "react";
import {useQueryParams} from "../Components/QueryParams";
import api from "../api";
import {GlobalContext} from "../context/GlobalContext";

const useOffers = () => {
    const {gs, updateGS} = useContext(GlobalContext);
    const {defaults} = useDefaults();

    let {showOffers} = useQueryParams();
    const setInitialOffers = useCallback(async (page: string = 'showOffers') => {
        const key: any = {
            showAll: 'showAll',
            showOffers: 'showOffers',
            showPromo: 'showPromo',
        }

        const offers = await api.getAll();
        if (!defaults) return;
        const defaultShowOffers = defaults[key[page]] ? defaults[key[page]].split(',').map((item: string) => +item) : null;
        const queryShowOffers = showOffers ? showOffers.split(',').map((item: string) => +item) : null;

        updateGS('promoOffers', offers.filter((offer: any) => offer.promo));

        if (queryShowOffers) {
            updateGS('offers', offers.filter((item: any) => queryShowOffers.includes(item.id)).map((offerItem: any) => ({
                ...offerItem,
                order: queryShowOffers.findIndex((item: any) => item === offerItem.id)
            })));
            return
        }

        if (defaultShowOffers) {
            updateGS('offers', offers.filter((item: any) => defaultShowOffers.includes(item.id)).map((offerItem: any) => ({
                ...offerItem,
                order: defaultShowOffers.findIndex((item: any) => item === offerItem.id)
            })))
        } else {
            updateGS('offers', offers)
        }
    }, [showOffers, defaults]);

    return {
        offers: gs?.offers ?? [],
        promoOffers: gs?.promoOffers ?? [],
        setInitialOffers,
    }
}

export default useOffers;
