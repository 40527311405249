import React from 'react';

const LockIcon = () => {
    return (
        <svg width="50" height="62" viewBox="0 0 50 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 0C16.3844 0 9.375 6.9533 9.375 15.5V24.8H6.25C4.5924 24.8 3.00268 25.4532 1.83058 26.6159C0.65848 27.7787 0 29.3557 0 31V55.8C0 57.4443 0.65848 59.0213 1.83058 60.1841C3.00268 61.3468 4.5924 62 6.25 62H43.75C45.4076 62 46.9973 61.3468 48.1694 60.1841C49.3415 59.0213 50 57.4443 50 55.8V31C50 29.3557 49.3415 27.7787 48.1694 26.6159C46.9973 25.4532 45.4076 24.8 43.75 24.8H40.625V15.5C40.625 6.9533 33.6156 0 25 0ZM15.625 15.5C15.625 10.3726 19.8312 6.2 25 6.2C30.1688 6.2 34.375 10.3726 34.375 15.5V24.8H15.625V15.5ZM28.125 48.7413V55.8H21.875V48.7413C20.7825 48.1207 19.9052 47.1861 19.3588 46.0609C18.8125 44.9357 18.6228 43.6726 18.8146 42.4383C19.0065 41.2039 19.571 40.0563 20.4336 39.1466C21.2963 38.2369 22.4166 37.6079 23.6469 37.3426C24.5607 37.1422 25.5082 37.1478 26.4195 37.359C27.3309 37.5702 28.1828 37.9816 28.9125 38.563C29.6421 39.1443 30.231 39.8807 30.6355 40.7179C31.0401 41.555 31.2501 42.4716 31.25 43.4C31.2482 44.4842 30.9584 45.5488 30.4098 46.4865C29.8612 47.4242 29.0731 48.2019 28.125 48.7413Z" fill="url(#paint0_linear_433_1033)"/>
            <defs>
                <linearGradient id="paint0_linear_433_1033" x1="25" y1="0" x2="25" y2="62" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#000057"/>
                    <stop offset="1" stopColor="#00007B"/>
                </linearGradient>
            </defs>
        </svg>

    );
};

export default LockIcon;