import React, {FC} from 'react';
import CardOffer from "../Components/CardOffer";
import IOffers from "../interface/IOffers";

const General: FC<IOffers> = ({state, updateOffers}) => {
    return (
        <>
            <div className="title__bg">
                <p className="title__rozdil">CASINO RATING</p>
            </div>
            <div className="card__container">
                {state?.map((item: any, index: any) => (
                    <CardOffer key={index + item.title + item.id} item={item} updateOffers={updateOffers}/>
                ))}
            </div>
        </>
    );
};

export default General;
