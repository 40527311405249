import React, {useEffect} from 'react';
import {Route, Switch, useLocation} from "react-router-dom";
import Layout from "./Layout";
import ContainerGeneral from "../containers/ContainerGeneral";
import ContainerPromokod from "../containers/ContainerPromokod";
import ContainerAllCasino from "../containers/ContainerAllCasino";
import useAuth from "../hooks/useAuth";
import useDefaults from "../hooks/useDefaults";
import ThankPageFb from "../pages/RegisterFacebook/ThankPageFB";
import RedirectPage from "../pages/RedirectPage";
import ReactGA from "react-ga4";
import {useQueryParams} from "./QueryParams";
import RedirectOfferSelzy from "../pages/RedirectOfferSelzy";

const AppRoutes = () => {
    const {checkAuth} = useAuth();
    const {getDefaults} = useDefaults();
    let {pid, sub1, sub2, clickid, ref_id} = useQueryParams();
    const {defaults} = useDefaults();
    useEffect(() => {
        checkAuth().then(() => {
        })
        getDefaults().then(() => {
        })
    }, []);
    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    useEffect(() => {
        let dataQuery = searchParams.get('recreative');
        if (dataQuery === "true") {
            sessionStorage.setItem('recreative', "true");
        }
    }, [])
    useEffect(() => {
        const lsPid = window.localStorage.getItem('pid');
        const lsSub1 = window.localStorage.getItem('sub1');
        const lsSub2 = window.localStorage.getItem('sub2');
        const lsSub3 = window.localStorage.getItem('clickid');
        const refId = window.localStorage.getItem('ref_id');
        if (defaults.pid) {
            window.localStorage.setItem('pid', Boolean(pid) ? pid : Boolean(lsPid) ? lsPid : defaults.pid);
            window.localStorage.setItem('sub1', Boolean(sub1) ? sub1 : Boolean(lsSub1) ? lsSub1 : defaults.sub1);
            window.localStorage.setItem('sub2', Boolean(sub2) ? sub2 : Boolean(lsSub2) ? lsSub2 : "direct");
            if (clickid) {
                window.localStorage.setItem('clickid', Boolean(clickid) ? clickid : Boolean(lsSub3) ?? lsSub3);
            }
            if (ref_id) {
                window.localStorage.setItem('ref_id', Boolean(ref_id) ? ref_id : Boolean(refId) ?? refId);
            }
        }
        if (lsPid === "22" && defaults.pid) {
            window.localStorage.removeItem('pid');
            window.localStorage.setItem('pid', defaults.pid);
        }
    }, [defaults, pid, sub1]);

    const usePageViews = () => {
        let location = useLocation();
        useEffect(() => {
            ReactGA.initialize('G-HVX24ZG10E');
            ReactGA.send({hitType: 'pageview', page: location.pathname});
        }, [location]);
    };
    usePageViews();

    return (
        <Switch>
            <Route
                path="/thank"
                exact
            >
                <ThankPageFb/>
            </Route>
            <Layout>
                <Route
                    path="/"
                    exact
                >
                    <ContainerGeneral/>
                </Route>
                <Route
                    path="/redirect"
                    exact
                >
                    <RedirectPage/>
                </Route>
                <Route
                    path="/redirs"
                    exact
                >
                    <RedirectOfferSelzy/>
                </Route>
                {/*<Route*/}
                {/*    path="/promokod"*/}
                {/*    exact*/}
                {/*>*/}
                {/*    <ContainerPromokod/>*/}
                {/*</Route>*/}
                {/*<Route*/}
                {/*    path="/allcasino"*/}
                {/*    exact*/}
                {/*>*/}
                {/*    <ContainerAllCasino/>*/}
                {/*</Route>*/}
            </Layout>
        </Switch>

    );
}

export default AppRoutes;
