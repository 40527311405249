import  {useContext} from "react";
import {GlobalContext} from "../context/GlobalContext";
import api from "../api";
import {GoogleData, LoginData, RegisterData} from "../api/users";

const useAuth = () => {
    const {gs, updateGS} = useContext(GlobalContext);

    const authorize = (token: string) => {
        if(!token) return;

        window.localStorage.setItem('token', token);
        updateGS('isAuth', true);
    }

    const login = async (loginData: LoginData) => {
        const data = await api.users.login(loginData);

        authorize(data.token);
        await checkAuth();
    }
    const loginResend = async () => {
        const data = await api.users.loginResend();

        authorize(data.token);
        await checkAuth();
    }

    const register = async (registerData: RegisterData) => {
        const data = await api.users.register(registerData);

        authorize(data.token);
        await checkAuth();
    }

    const loginViaGoogle = async (googleData: GoogleData) => {
        const data = await api.users.loginViaGoogle(googleData);

        authorize(data.token);
        await checkAuth();
    }

    const checkAuth = async () => {
        const data = await api.users.getMe();

        updateGS('isAuth', Boolean(data));
        updateGS('userData', data);
    }

    const logout = () => {
        window.localStorage.removeItem('token');
        updateGS('isAuth', false);
        updateGS('userData', null);
    }

    return {
        isAuth: gs?.isAuth ?? false,
        user: gs?.userData ?? {},
        authorize,
        logout,
        checkAuth,
        login,
        loginResend,
        register,
        loginViaGoogle,

    }
}

export default useAuth;