import {createContext, ReactNode, useCallback, useState} from "react";

export const GlobalContext = createContext({} as any);

const GlobalContextProvider = ({children}: { children: ReactNode }) => {
    const [gs, setGlobalState] = useState<any>();

    const updateGS = useCallback((prop: string, value: any) =>
        setGlobalState((prev: any) => ({...prev, [prop]: value})), []);


    return (
        <GlobalContext.Provider
            value={{gs, updateGS,}}>{children}</GlobalContext.Provider>
    );
};

export default GlobalContextProvider;
