import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__sub__container">
                <h3 className="footer__sub__title__number">18+</h3>
                <p className="footer__text__copyright">COPYRIGHT © 2022
                    CLUB CASINO</p>
            </div>
            <div className="footer__sub__container footer__sub__vidmova">
                <h3 className="footer__sub__title">DISCLAIMER OF LIABILITY</h3>
                <p className="footer__text">You must ensure that you comply with all age and other regulatory
                    requirements
                    requirements before entering the casino or placing a bet. information on this site is presented
                    for casino players only.</p>
            </div>
            <div className="footer__sub__container footer__sub__gra ">
                <h3 className="footer__sub__title">RESPONSIBLE GAME</h3>
                <p className="footer__text">The Club Casino site does not play games for money and is not responsible
                    for any
                    what are the losses from playing in the casino. We always encourage the use of appropriate gambling.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
