import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";

const RedirectOfferSelzy = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const pid = query.get("pid");
    const offerId = query.get("offer_id");
    const sub1 = query.get("sub1");
        let link = `https://axioma.g2afse.com/click?pid=${pid}&offer_id=${offerId}&sub1=${sub1}&sub2=mailmass`
            useEffect(()=>{
                window.location.href = link;
            },[])
    return (
        <div>
            <h2 style={{
                fontWeight: 700,
                fontSize: "26px",
                lineHeight: "36px",
                textAlign: "center",
                color: "#4C4E73",
                paddingTop: "54px",
            }}>Перенаправлення</h2>
        </div>
    );
};

export default RedirectOfferSelzy;