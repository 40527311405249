import React, {useEffect} from 'react';

const RedirectPage = () => {
    const Redirect = () => {
        const link = window.localStorage.getItem("mainLink");
        const viberLink = window.localStorage.getItem("viberLink");
        // let width = window.innerWidth;
        // if (width > 500) {
        //     // @ts-ignore
        //     window.location.href = viberLink;
        // } else {
        // @ts-ignore
        // window.location.href = viberLink !== "" ? viberLink : window.localStorage.getItem("viberLink");
        setTimeout(function () {
            // @ts-ignore
            window.location.href = link !== "" ? link : window.localStorage.getItem("mainLink");
        }, 50);
        // }
    };
    useEffect(() => {
        Redirect();
    }, [])
    return (
        <div className="deep-link">
        </div>
    );
};

export default RedirectPage;