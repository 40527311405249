import React, {FC} from 'react';

interface ChatProps {
    visible:boolean
}

const ChatIcon:FC<ChatProps> = ({visible}) => {
    return (
        <>
            {!visible ?
                <svg width="61" height="62" viewBox="0 0 61 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30.2397" cy="31.1986" r="30.2397" fill="url(#paint0_linear_1146_1526)"/>
                    <path
                        d="M25.1998 41.2786L30.2398 47.0385L35.2797 41.2786H40.3197C41.908 41.2786 43.1997 39.9869 43.1997 38.3986V21.1188C43.1997 19.5305 41.908 18.2388 40.3197 18.2388H20.1599C18.5716 18.2388 17.2799 19.5305 17.2799 21.1188V38.3986C17.2799 39.9869 18.5716 41.2786 20.1599 41.2786H25.1998ZM23.0399 25.4387H37.4397V28.3187H23.0399V25.4387ZM23.0399 31.1987H33.1198V34.0786H23.0399V31.1987Z"
                        fill="white"/>
                    <defs>
                        <linearGradient id="paint0_linear_1146_1526" x1="0" y1="0.958923" x2="60.4795" y2="61.4384"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#60A1EC"/>
                            <stop offset="1" stopColor="#46D8F8"/>
                        </linearGradient>
                    </defs>
                </svg>
            :
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30" cy="30" r="30" fill="url(#paint0_linear_1139_1533)"/>
                    <path d="M17.7268 17.7268C18.1924 17.2614 18.8237 17 19.4819 17C20.1402 17 20.7715 17.2614 21.237 17.7268L30.015 26.5048L38.793 17.7268C39.2612 17.2746 39.8882 17.0244 40.5391 17.0301C41.19 17.0357 41.8126 17.2968 42.2729 17.7571C42.7332 18.2173 42.9943 18.84 42.9999 19.4909C43.0056 20.1418 42.7553 20.7688 42.3031 21.237L33.5252 30.015L42.3031 38.793C42.7553 39.2612 43.0056 39.8882 42.9999 40.5391C42.9943 41.19 42.7332 41.8126 42.2729 42.2729C41.8126 42.7332 41.19 42.9943 40.5391 42.9999C39.8882 43.0056 39.2612 42.7553 38.793 42.3031L30.015 33.5252L21.237 42.3031C20.7688 42.7553 20.1418 43.0056 19.4909 42.9999C18.84 42.9943 18.2173 42.7332 17.7571 42.2729C17.2968 41.8126 17.0357 41.19 17.0301 40.5391C17.0244 39.8882 17.2746 39.2612 17.7268 38.793L26.5048 30.015L17.7268 21.237C17.2614 20.7715 17 20.1402 17 19.4819C17 18.8237 17.2614 18.1924 17.7268 17.7268V17.7268Z" fill="white"/>
                    <defs>
                        <linearGradient id="paint0_linear_1139_1533" x1="0" y1="0" x2="60" y2="60" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#60A1EC"/>
                            <stop offset="1" stopColor="#46D8F8"/>
                        </linearGradient>
                    </defs>
                </svg>

            }
        </>
    );
};

export default ChatIcon;