import React, {useState} from 'react';
// @ts-ignore
import logo from "../assets/header/ClubCasinoLogo.png";
import Burger from "./Burger";
import {Link, NavLink, useLocation} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {useDispatch, useSelector} from "react-redux";
import {removePopup} from "../store/reducers/PopupReducer";
import {Spin as Hamburger} from 'hamburger-react';

const Header = () => {
    const popup = useSelector((state: any) => state.popup);
    const location = useLocation();
    const dispatch = useDispatch();
    const removeBtnPopup = () => {
        dispatch(removePopup());
    }
    const [isOpen, setOpen] = useState<boolean>(false);
    const test = () => {
        setOpen(!isOpen);
    };
    return (
        <header className="header">
            <div className="header__wrapper">
                <Link to="/" className="header__title__logo__container">
                    <img className="header__logo" src={logo} alt="logotype ClubCasino"/>
                    <div className="header__title__container">
                        <h1 className="header__title header__title__yellow">CLUB</h1>
                        <h1 className="header__title">CASINO</h1>
                    </div>
                </Link>
                {/*<div className="header__menu__wrapper">*/}
                {/*    <div className="header__menu">*/}
                {/*        <ul className="header__list__container">*/}
                {/*            <li className="header__list">*/}
                {/*                <NavLink activeClassName="active__route" exact*/}
                {/*                         className={popup ? "header__list__link header__list__link__disabled" : "header__list__link"}*/}
                {/*                         onClick={removeBtnPopup} to="/">*/}
                {/*                    Main*/}
                {/*                </NavLink>*/}
                {/*            </li>*/}
                {/*            {location.pathname !== "/register" && location.pathname !== "/login" &&*/}
                {/*                <>*/}
                {/*                    <li className="header__list">*/}
                {/*                        <NavLink exact activeClassName="active__route"*/}
                {/*                                 className={popup ? `header__list__link header__list__link__disabled` : "header__list__link"}*/}
                {/*                                 to="/promokod">*/}
                {/*                            <span className="header__list__span__promo"/> Promo codes*/}
                {/*                        </NavLink>*/}
                {/*                    </li>*/}
                {/*                    <li className="header__list">*/}
                {/*                        <NavLink exact activeClassName="active__route" onClick={removeBtnPopup}*/}
                {/*                                 className={popup ? "header__list__link header__list__link__disabled" : "header__list__link"}*/}
                {/*                                 to="/allcasino">*/}
                {/*                            All casinos*/}
                {/*                        </NavLink>*/}
                {/*                    </li>*/}
                {/*                </>*/}
                {/*            }*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="hamburger__container">*/}
                {/*        <Hamburger size={30} color={"#000057"} toggled={isOpen} toggle={() => {*/}
                {/*            setOpen(!isOpen)*/}
                {/*            removeBtnPopup()*/}
                {/*        }*/}
                {/*        }/>*/}
                {/*    </div>*/}
                {/*    <div className="burger__container">*/}
                {/*        <Burger isOpen={isOpen} setIsOpen={test} removeBtnPopup={removeBtnPopup} pageWrapId='page-wrap'*/}
                {/*                outerContainerId='outer-container'/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </header>
    );
};

export default Header;
